main {
    flex: 1; /* 콘텐츠 영역을 확장하여 나머지 공간을 차지 */
}

.footer {
    background-color: #000;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;
}

.footer-links a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}

.contact-info {
    margin-top: 10px;
    font-size: 10px;
}

.social-media a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}

.newsletter {
    margin-top: 20px;
}

.newsletter input {
    padding: 10px;
    font-size: 14px;
    width: 200px;
}

.newsletter button {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    margin-left: 10px;
}
