/*.product-grid {*/
/*    padding: 20px;*/
/*}*/

/*.product-list {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    gap: 20px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.product-grid-card {*/
/*    border: 1.5px solid #ccc;*/
/*    !*border: 1.5px white;*!*/

/*    border-radius: 8px;*/
/*    overflow: hidden;*/
/*    text-align: center;*/
/*    align-items: center;*/
/*    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
/*    transition: transform 0.3s ease-in-out;*/
/*    display: flex; !* Flex 컨테이너로 설정 *!*/
/*    flex-direction: column; !* 세로 정렬 *!*/
/*    justify-content: space-between; !* 상하로 공간을 분배 *!*/

/*    !*width: 100%; !* 크기를 유연하게 조정 *!*!*/
/*    width: 300px;*/

/*    height: 650px;*/
/*    !*height: 450px;*!*/

/*    flex-grow: 1;*/
/*}*/

/*.product-grid-card:hover {*/
/*    transform: scale(1.05);*/
/*}*/

/*.product-image {*/
/*    width: 100%;*/
/*    height: auto;*/
/*    margin: 0 auto;*/
/*    margin-top: auto;*/
/*    margin-bottom: auto;*/
/*}*/

/*.product-info {*/
/*    padding: 10px;*/

/*}*/

/*.product-grid-name {*/
/*    font-size: 1.5em;*/
/*    color: black;*/
/*    margin-bottom: 35px;*/
/*}*/

/*.product-grid-price {*/
/*    font-size: 1em;*/
/*    color: #888;*/
/*    margin-bottom: 25px;*/

/*}*/

/*!*.original-price {*!*/
/*!*    text-decoration: line-through;*!*/
/*!*    margin-right: 5px;*!*/
/*!*}*!*/

/*!*.sale-price {*!*/
/*!*    color: red;*!*/
/*!*}*!*/

/*.product-grid-description {*/
/*    font-size: 0.9em;*/
/*    color: #555;*/
/*    margin-bottom: 20px;*/

/*}*/

/*.product-grid-button {*/
/*    background-color: #000;*/
/*    color: #fff;*/
/*    border: none;*/
/*    padding: 10px;*/
/*    cursor: pointer;*/
/*    border-radius: 4px;*/
/*    transition: background-color 0.3s ease-in-out;*/
/*    margin-top: auto;*/
/*}*/

/*.product-button:hover {*/
/*    background-color: #444;*/
/*}*/

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* 카드의 최소 너비를 200px로 설정하고, 화면 크기에 따라 자동으로 칸이 조정됩니다. */
    gap: 16px; /* 카드 간격 설정 */
    padding: 16px;
}

.product-card {
    background-color: #fff;
    /*border: 1px solid #ddd;*/
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-image {
    width: 100%;
    height: 150px;
    object-fit: cover; /* 이미지 비율을 유지하면서 카드에 맞춰줍니다 */
    border-radius: 4px;
    margin-bottom: 8px;
}

.product-info {
    text-align: start;
}

.product-info h3 {
    font-size: 15px;
    /*margin: 10px 0;*/
}

.product-info p {
    font-size: 13px;
    color: #888888;
}

.product-info span {
    font-size: 13px;
    color: #007bff;
}


