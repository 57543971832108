.order-summary {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    margin: 0 auto;
}

.order-summary-header {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.order-summary-body {
    margin-bottom: 20px;
}

.order-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.order-item-img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.order-item-details {
    flex-grow: 1;
}

.order-item-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.order-item-description {
    font-size: 12px;
    color: #555;
    margin: 5px 0;
}

.order-item-price {
    font-size: 14px;
    color: #333;
}

.order-summary-shipping {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.order-summary-footer {
    text-align: center;
}

.order-summary-amount,
.order-summary-discount,
.order-summary-total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.total-amount {
    font-size: 18px;
    font-weight: bold;
    color: #f00;
}

.order-summary-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

.order-summary-button:hover {
    background-color: #0056b3;
}

.order-summary-button-general{
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}
.order-summary-button-general:hover {
    background-color: #0056b3;
}
