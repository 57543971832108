.hero-section {
    position: relative;
    display: flex;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
    text-align: center;
    width: 100%;
    max-width: 1200px; /* 부모 요소의 최대 너비를 1200px로 제한 */
    margin: 0 auto; /* 가운데 정렬 */
    overflow: hidden;
    border-bottom: 1px solid #ddd;
}

.hero-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.hero-image {
    /* width: 65vw;
    height: 150px;
    object-fit: contain; */
    max-width: 100%; /* 부모 요소의 최대 너비를 기준으로 이미지 조정 */
    height: auto; /* 이미지 비율 유지 */
    object-fit: cover; /* 컨테이너를 넘지 않고 채워지도록 설정 */
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Noto Sans', sans-serif;
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 50px;
}

.hero-button {
    margin-top: 150px;
    color: #cccccc;
    text-decoration: white;
    font-size: 30px;
    font-weight: 1000;
    /*border-bottom: 1px solid white;*/
    padding-bottom: 2px;
    transition: color 0.3s, border-bottom-color 30s;
}

.hero-button:hover {
    color: #f0f0f0;
    border-bottom-color: #f0f0f0;
}

@media (max-width: 1200px) {

    .hero-section {
        width: 80%; /* 큰 화면에서는 80% */
    }
    .main-content {
        width: 80%; /* main-content도 동일하게 80% */
    }
}

@media (max-width: 1000px) {

    .hero-section {
        width: 60%; /* 큰 화면에서는 80% */
    }
    .main-content {
        width: 60%; /* main-content도 동일하게 80% */
    }
}

@media (max-width: 700px) {

    .hero-section {
        width: 50%; /* 큰 화면에서는 80% */
    }
    .main-content {
        width: 50%; /* main-content도 동일하게 80% */
    }
}

@media (max-width: 600px) {

    .hero-button {
        font-size: 10px; /* 작은 화면에서 버튼 글씨 크기 조정 */
        padding-bottom: 30px;
        margin-bottom: 80px;
    }

    .hero-buttons {
        gap: 25px;
    }
}

@media (max-width: 480px) {

    .hero-button {
        font-size: 7px; /* 더 작은 화면에서 버튼 글씨 크기 조정 */
        padding-bottom: 30px;
    }
    .hero-buttons {
        gap: 35px;
    }
}