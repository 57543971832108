.product-page-image {
    flex: 0 0 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-page-image img {
    height: auto;
    object-fit: contain;
    max-width: 100%;
}
