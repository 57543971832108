.App {
  /*border: 2px solid red; !* 빨간색 테두리 설정 *!*/
  margin: 0 auto; /* 가운데 정렬 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 크기 계산 */
}
.App_sub{
  /*border: 2px solid red; /* 빨간색 테두리 설정 */
  margin: 0 auto; /* 가운데 정렬 */
  max-width: 1200px; /* 최대 너비 설정, 필요에 따라 조정 */
  /*padding: 20px; !* 내부 여백 *!*/
  box-sizing: border-box; /* 패딩과 테두리를 포함한 크기 계산 */
}

.App-header {
  background-color: #000;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1 {
  color: #fff;
  margin: 0;
  font-size: 24px;
}

.App-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
}

.App-nav li {
  display: inline;
}

.App-nav a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.chalkboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  background-color: #f5f5f5;
}

.chalkboard {
  width: 80%;
  max-width: 800px;
  height: 400px;
  background-color: #2f4f4f;
  border: 5px solid #8b4513;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chalkboard h2 {
  color: #fff;
  font-size: 36px;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.image-container {
  margin-top: 20px;
}

.responsive-image {
  width: 60%;
  height: auto;
}

body {
  font-family: 'Noto Sans', sans-serif;
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  width: 100%;
}
