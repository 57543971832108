/* ProductPage.css */
.product-page-container {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    align-items: center;
    flex-direction: column;
    padding: 20px;
    display: flex;
    gap: 40px;
}

.product-page {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
    width: 100%;
    background: #fff;
    overflow: hidden;
}

@media (max-width: 768px) {
    .product-page {
        flex-direction: column;
        align-items: center;
    }

    .product-page-image {
        max-width: 100%;
    }

    .product-page-info {
        width: 100%;
        align-items: center;
        text-align: center;
        margin-top: 80px;
    }

    .product-details {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-details span {
        display: block;
        margin-bottom: 5px;
    }

    .product-page-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .add-to-cart,
    .buy-now {
        flex: 1;
        max-width: 150px;
    }
}