.product-details-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 30px;
    margin-top: 30px;
}

.product-details-price {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    margin-left: 280px;

}


.details-price {
    font-size: 15px;
    font-weight: bold;
}

.product-details-title-info {
    display: flex;
    flex-direction: column; /* 수직 정렬을 위해 컬럼 방향으로 설정 */
    align-items: flex-start; /* 텍스트들을 수직 가운데 정렬 */
    text-align: center;
    gap: 5px;
    border-bottom: 1px solid #ddd; /* 연한 색의 선 추가 */
    padding-bottom: 30px; /* 선과 텍스트 사이 간격 */
}

.product-details-title {
    font-size: 22px;
    font-weight: bold;

}

.product-details-title-info span {
    font-size: 11px;
    color: #888;
}

.product-details-delivery {
    font-size: 14px;
    color: #888;
}

.product-details-quantity {
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid #ddd; /* 연한 색의 선 추가 */
    padding-bottom: 5px;
}

.quantity-details-button {
    background-color: #f0f0f0;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #888;
    cursor: pointer;
}

.details-quantity {
    font-size: 14px;
    color: #888;
}

.product-details-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
}

.add-to-cart {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
}

.buy-now {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    margin-left: 15px;
    margin-top: 20px;
}

.product-reference span {
    font-size: 14px;
    color: #888;
}

